import 'mapbox-gl/dist/mapbox-gl.css';
import './css/main.css';

import barba from '@barba/core';

import { gsap } from "gsap";

barba.init({
    debug: true,
    timeout: 7000,
    transitions: [
      {
      name: 'opacity-transition',
      leave(data) {
        return gsap.to(data.current.container, {
          opacity: 0
        });
      },
      enter(data) {
        
      },
      after(data) {
        return gsap.from(data.next.container, {
            opacity: 0
          });
        
        
      },
      once(data) {
        mapCheck();
      },
      afterOnce(data) {
        galleryCheck();
        openPopCheck();
      }
    }],
});

function mapCheck() {
    let maps = document.getElementsByClassName('map');
    if (maps.length > 0) {
        import(/* webpackChunkName: "map" */ "./js/components/map.js").then(module => {
            const mapGo = module.default;
            mapGo();
          });
    }
}

function galleryCheck() {
  let galleries = document.getElementsByClassName('gallery');
  if (galleries.length > 0) {
    import(/* webpackChunkName: "gallery" */ "./js/components/gallery.js").then(module => {
      const galleryGo = module.default;
      galleryGo();
  });
};
};


function hamburgerToggle(preset) {
  import(/* webpackChunkName: "hamburgerToggle" */ "./js/components/hamburgerToggle.js").then(module => {
    const toggle = module.default;
    toggle(preset);
  });
}

let dropdownCloser = document.getElementById('dropdownCloser');
dropdownCloser.addEventListener('click', function() {hamburgerToggle('c')});

let hamburgerOuter = document.getElementById("hamburgerOuter");
hamburgerOuter.addEventListener("click", function() {hamburgerToggle()});


function openPopCheck() {
let popupOpeners = document.getElementsByClassName('openPopup');
  if (popupOpeners.length > 0) {
    import(/* webpackChunkName: "popupOpener" */ "./js/components/popupOpener.js").then(module => {
      const openPopup = module.default;
      openPopup();
    });
  };
};


barba.hooks.after((data) => {
    mapCheck();
    galleryCheck();
    window.scrollTo(0, 0);
    openPopCheck();
});
barba.hooks.afterLeave((data) => {
  
})
barba.hooks.before((data) => {
  hamburgerToggle('c');
})

barba.hooks.leave((data) => {
  map.remove();
})